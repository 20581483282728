import { Link } from 'react-router-dom';
import '../App.css';
import '../css/FancyBtn.css';
import { VersionNumber } from '../util/VersionNumber';
import GoogleIcon from '../assets/icons/GoogleIcon.png';
import AppleIcon from '../assets/icons/AppleIcon.png';
import EmailIcon from '../assets/icons/EmailIcon.png';
import GameLogo from '../assets/images/JBLTimeplayLogo.png';
import { useKeycloak } from '@react-keycloak/web';

export const WelcomePage = ({ signInAsGuest, signInKeycloak }) => {
    console.log(`Load WelcomePage`);
    const { keycloak } = useKeycloak();

    const getLogo = () =>{
        if (process.env.REACT_APP_BUILD_TYPE == 'event'){
            return './images/jeopardy_event_logo.png';
        } else {
            return GameLogo;
        }
    }

    const handleThirdPartyLoginRedirect = (provider) => {
        if (!!provider && provider !== '') {
            const idpHint = provider === 'google' ? 'google' : 'apple';
            const redirectUri = window.location.origin;

            keycloak
                .login({
                    idpHint,
                    redirectUri,
                })
                .then(() => {
                    console.log(`[HandleThirdPartyLogin] Redirecting to ${provider} for login.`);
                })
                .catch((error) => {
                    console.error(
                        `[HandleThirdPartyLogin] Failed to redirect to ${provider}:`,
                        error,
                    );
                });
        } else {
            console.log('[HandleThirdPartyLogin] no provider has been specified.');
        }
    };

    return (
        <div>
            <div className="pg-fullscreen">
                <img src={getLogo()} className="jeopardy-logo" alt="logo" />
                <br></br>
                <br></br>

                {/* Google login button */}
                <button
                    className="custom-button"
                    id="ContinueWithLoginBtn"
                    onClick={() => handleThirdPartyLoginRedirect('google')}
                >
                    <img src={GoogleIcon} alt="Google" />
                    <span>Continue with Google</span>
                </button>

                {/* Apple login button */}
                <button
                    className="custom-button"
                    id="ContinueWithLoginBtn"
                    onClick={() => handleThirdPartyLoginRedirect('apple')}
                >
                    <img src={AppleIcon} alt="Apple" />
                    <span>Continue with Apple</span>
                </button>

                <Link to="/*">
                    <button className="custom-button" id="ContinueWithLoginBtn">
                        <img src={EmailIcon} alt="Email" />
                        <span>Continue with Email</span>
                    </button>
                </Link>

                <div>
                    <button
                        className="custom-button"
                        onClick={signInAsGuest}
                        id="ContinueAsGuestBtn"
                    >
                        <span>Play as Guest</span>
                    </button>
                </div>

                <p className="disclaimer">TimePlay a division of STREAMSIX</p>

                <VersionNumber></VersionNumber>
            </div>
        </div>
    );
};
