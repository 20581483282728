import config from '../config';
const WebSocket = window.WebSocket || window.MozWebSocket;
const { DeviceID } = require('./rmb-util');
const { BusClient, BusClientOperation, PeerType, BusCode } = require('./rmb-lib');

const { updateSearchParams, deleteSearchParams } = require('../util/util');
const { GenerateGuestToken } = require('../util/guestDataHandler');

export class RMBClient {
    constructor(keycloak, licenses) {
        this.keycloak = keycloak;
        this.licenses = licenses;
        this.customTarget = 0;
        this.connected = false;
        this.sessionUrl = undefined; 
        this.deviceId = DeviceID.getId();
        this.playerId = this.getPlayerID(); 
        this.nickname = this.getNickname();
        this.isOpcon = false;

        // Setup RMB client
        this.busClient = new BusClient(this.playerId, this.deviceId, WebSocket, console.log, console.warn, console.error);
        this.busClient.setListener(BusClientOperation.Connected, this.onConnected);
        this.busClient.setListener(BusClientOperation.Error, this.onError);
        this.busClient.setListener(BusClientOperation.Data, this.onData);
        this.busClient.setListener(BusClientOperation.ControllerData, this.onControllerData);
        this.busClient.setListener(BusClientOperation.Closed, this.onClose);
        console.log(`[rmb-client] Set up RMB with the data: \nnickname[${this.nickname}], \nplayerId[${this.playerId}] \ndeviceId[${this.deviceId}]`);
    }

    refreshKeycloakData = (keycloak) =>
    {
        this.keycloak = keycloak;
        this.playerId = this.getPlayerID(); 
        this.nickname = this.getNickname();

        if(this.busClient != undefined) {
            console.log(`[rmb-client] attempting to clear old busClient`);
            try {
                this.busClient.disconnect();
                this.busClient.clearAllListeners();
                this.busClient = undefined;
                console.log(`[rmb-client] succesfully cleared old busClient`);
            } catch (error) {
                console.warn(`[rmb-client] failed to clear old busClient with error: ${error}`);
            }
        } else{
            console.log(`[rmb-client] busClient was already null`);
        }

        this.busClient = new BusClient(this.playerId, this.deviceId, WebSocket, console.log, console.warn, console.error);
        this.busClient.setListener(BusClientOperation.Connected, this.onConnected);
        this.busClient.setListener(BusClientOperation.Error, this.onError);
        this.busClient.setListener(BusClientOperation.Data, this.onData);
        this.busClient.setListener(BusClientOperation.ControllerData, this.onControllerData);
        this.busClient.setListener(BusClientOperation.Closed, this.onClose);

        console.log(`[rmb-client] Updated with keycloak data: \nnickname[${this.nickname}], \nplayerId[${this.playerId}] \ndeviceId[${this.deviceId}]`);
    }

    isGuest = () => {
        return localStorage.getItem('isGuest') === 'true'; 
    };

    setOpconStatus = (toggleOpcon) =>{
        this.isOpcon = toggleOpcon;
        console.log(`[rmb-client] opcon status: ${this.isOpcon}`);
    }

    onData = (data) => {
        try {
            //RMB lib keeps data in ASCII format, this converts it to json 
            let json = '';
            for (let i = 0; i < data.length; i++) {
                json += String.fromCharCode(data[i]); 
            }
            console.log(`[rmb-client] received the msg: ${json}`);
            if (this.onDataCallback != undefined) {
                this.onDataCallback(json);
            } else {
                console.warn(`[rmb-client] there is no onData callback assigned`);
            }
        } catch (error) {
            console.error(`[rmb-client] Error onData parsing JSON: ${error}`);
        }
    };
    onControllerData = (data) => {
        try {
            //RMB lib keeps data in ASCII format, this converts it to json 
            console.log(`[rmb-client] received controller data...`);
            let json = '';
            for (let i = 0; i < data.length; i++) {
                json += String.fromCharCode(data[i]); 
            }
            console.log(`[rmb-client] received controller msg: ${json}`);
            const parsed = JSON.parse(json);
            this.customTarget = parsed["700"];
            console.log(`[rmb-client] stored custom target value ${this.customTarget}`);
        } catch (error) {
            console.error(`[rmb-client] Error onData parsing JSON: ${error}`);
        }
    };

    

    onConnected = (connectionId) => {
        console.log(`[rmb-client] Connected with connection ID: ${connectionId}`);
        this.connected = true;
        this.busClient.setPlayerId();

        const playerInfo = { "_device": {"deviceId": this.deviceId, "deviceOSType": "browser", "appVersion": "unknown", "carrier": "none"}, "_deviceId": this.playerId, "_nickname": this.nickname, "_nicknameId": 0, "_avatarId": 0 };
        const jsonString = JSON.stringify({ "501": playerInfo });

        const buffer = new ArrayBuffer(jsonString.length);
        const dataview = new DataView(buffer);

        for (let i = 0, strLen = jsonString.length; i < strLen; i += 1) {
            dataview.setUint8(i, jsonString.charCodeAt(i));
        }

        this.busClient.sendData(buffer, PeerType.Room);
        console.log(`[rmb-client] sent request for customTarget: ${jsonString}`);

        if (this.onConnectedCallback != undefined) {
            this.onConnectedCallback(connectionId);
        } else {
            console.warn(`[rmb-client] there is no onConnected callback assigned`);
        }

        this.busClient.startKeepAlive();
    };

    onError = (url) => {
        console.error(`[rmb-client] Failed to connect to ${url}`);
        if (this.onErrorCallback != undefined) {
            this.onErrorCallback(url);
        } else {
            console.warn(`[rmb-client] there is no onError callback assigned`);
        }
    };

    onClose = (url) => {
        console.error(`[rmb-client] session[${url}] has ended`);
        if (this.onCloseCallback != undefined) {
            this.onCloseCallback(url);
        }
    };

    pageClosed = () => {
        console.log(`[rmb-client] Received page closed callback, clear rmb connection.`);
        if (this.busClient != undefined) {
            let ptr = this.busClient;
            this.busClient = undefined;
            ptr.disconnect();
            console.log(`[rmb-client] RMB disconnected in page closed.`);
        }
        
    }

    // Set up listeners for various events
    attachListener = (busClientOperation, callback) => {
        switch (busClientOperation) {
            case BusClientOperation.Connected:
                this.onConnectedCallback = callback;
                break;
            case BusClientOperation.Error:
                this.onErrorCallback = callback;
                break;
            case BusClientOperation.Data:
                this.onDataCallback = callback;
                break;
            case BusClientOperation.Closed:
                this.onCloseCallback = callback;
                break;
            default:
                if(this.busClient != undefined) {
                    this.busClient.setListener(busClientOperation, callback);
                } else {
                    console.log(`unable to attack listener ${busClientOperation} as busClient was null`);
                }
                break;
        }
    }

    connect = async(joinCode, nickname) => {
        // Get URL & Connect to the session
        updateSearchParams('jc', joinCode);
        console.log(`[rmb-client] saved the joincode: ${joinCode}`);
        const playerId = this.playerId;
        
        console.log(`[rmb-client] connecting with joinCode[${joinCode}] for game[${config.gameLicense}] in env[${config.lobbyServiceURL}]`);

        if (this.sessionUrl == undefined) {
            const payload = {"id": playerId, "nickname": nickname};
            const fullHeaders = {'Content-Type': 'application/json; charset=UTF-8', 'x-license': config.gameLicense, ... await this.getLoginHeaders() };
    
            let response = await fetch(
                `${config.lobbyServiceURL}/app/join/${joinCode}`, // Endpoint to join room
                { method: 'PUT', headers: fullHeaders, body: JSON.stringify(payload) }
            );
    
            if (this.isSuccess(response.status)) {
                const roomResponseData = await response.text();
                console.log(`[rmb-client] room response: ${roomResponseData}`);
                const roomData = JSON.parse(roomResponseData);
                const roomId = roomData.data.roomId;
                
    
                response = await fetch(
                    `${config.lobbyServiceURL}/room/${roomId}/join`,
                    { method: 'POST', headers: fullHeaders, body: JSON.stringify({"id": playerId, "nickname": nickname, "avatarId": 0, "deviceOsVersion": "0.0.0", "appVersion": "1.0.0-alpha"}) }
                );
    
                if (this.isSuccess(response.status)) {
                    console.log(`[rmb-client] got join response = ${await response.text()}`);
    
                    response = await fetch(
                        `${config.lobbyServiceURL}/bus/${roomId}`,
                        {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json; charset=UTF-8',
                                'x-session-id': roomId,
                                'x-license': config.gameLicense,
                                ... await this.getLoginHeaders()
                            }
                        }
                    );
    
                    if (this.isSuccess(response.status)) {
                        const busResponseData = await response.text();
                        console.log(`[rmb-client] bus response: ${busResponseData}`);
                        const busData = JSON.parse(busResponseData).data;
                        console.log(`[rmb-client] got the bus data: ${JSON.stringify(busData)}`);
                        this.sessionUrl = busData.websocket;
                        this.sessionId = roomId;
                        this.roomName = roomId;
                        this.connected = true;

                        console.log(`[rmb-client] playerID: ${playerId}`);
                        console.log(`[rmb-client] using socket[${this.sessionUrl}]`);
                    } else {
                        throw new Error(
                            `[rmb-client] Failed to post bus. ${await response.text()}`,
                        );
                    }
                } else {
                    throw new Error(`[rmb-client] Failed to join room. ${await response.text()}`);
                }
            } else {
                throw new Error(
                    `[rmb-client] Failed to lookup room ${response.status} => ${await response.text()}`,
                );
            }
        }

        if (this.sessionUrl != undefined) {
            console.log(`[rmb-client] attempting to connect to rmb[${this.sessionUrl}]...`);
            this.busClient.connect(this.sessionUrl);
        } else {
            throw new Error(`[rmb-client] failed to connect to rmb as sessionUrl was undefined...`);
        }
    };

    isSuccess = (statusCode) => {
        return statusCode >= 200 && statusCode < 300;
    };

    // Gets the user access token for the current env
    getLoginHeaders = async () => {
        // Generate new guest token if needed, then attempt to connect (keeping this code as backup, just in case...)
        // await GenerateGuestToken(this.getPlayerID())
        // console.log(`[rmb-client] Connecting with guest token: ${localStorage.getItem('guestToken')}`);
        // const loginHeaders = { "Authorization": `Bearer ${localStorage.getItem('guestToken')}` };
        // console.log(`[rmb-client] getLoginHeaders: ${JSON.stringify(loginHeaders)}`);
        // return loginHeaders;

        // If guest, use guest token, if logged in use keycloak
        if (this.isGuest()) {
            console.log(`[rmb-client] Connecting with guest token: ${localStorage.getItem('guestToken')}`);
            const loginHeaders = { "Authorization": `Bearer ${localStorage.getItem('guestToken')}` };
            console.log(`[rmb-client] getLoginHeaders: ${JSON.stringify(loginHeaders)}`);
            return loginHeaders;
        } else if(this.keycloak != undefined) {
            const loginHeaders = { "Authorization": `Bearer ${this.keycloak.token}` };
            console.log(`[rmb-client] getLoginHeaders: ${JSON.stringify(loginHeaders)}`);
            return loginHeaders;
        } 
    }

    // Disconnect from the session
    endSession = () => {
        console.log(`[rmb-client] endSession`);
        this.busClient.disconnect();
        this.connected = false;
    }

    // Send a message
    sendData = (dataObject) => {
        if (this.isOpcon === true && localStorage.getItem("isOpconDevice") === 'true'){
            this.sendAsOpcon(dataObject);
        } else {
            this.sendAsPlayer(dataObject);
        }   
    }

    sendAsPlayer = (dataObject) => {
        const jsonString = JSON.stringify(dataObject);
        console.log(`[rmb-client.sendData] Attempting to send the msg: ${jsonString}`);

        //TODO: discover if theres a better way than hard coding CustomPeerID = 50
        // Check in on this
        const target = this.customTarget <= 0 ? 50 : this.customTarget;
        const result = this.busClient.sendObject(dataObject, target); 
        if(result != 0){
            console.log(`[rmb-client.sendData] Success with result[${result}] sent the msg: ${jsonString}`);
        } else{
            console.error(`[rmb-client.sendData] Failed with result[${result}] to send the msg: ${jsonString}`);
        }
    }

    sendAsOpcon = (dataObject) => {
        const jsonString = JSON.stringify(dataObject);
        console.log(`[rmb-client.sendData] Attempting to send the opcon msg: ${jsonString}`);

        // This will always target the host app
        const result = this.busClient.sendObject(dataObject); 
        if(result != 0){
            console.log(`[rmb-client.sendData] Success with result[${result}] sent the opcon msg: ${jsonString}`);
        } else{
            console.error(`[rmb-client.sendData] Failed with result[${result}] to send the opcon msg: ${jsonString}`);
        }
    }

    flushCachedData = () =>{
        deleteSearchParams();
        localStorage.removeItem("ms_url");
        localStorage.removeItem("sat_url");
        console.log(`[rmb-client.flushCachedData] cleared cat`);
    }

    isConnected = () => {
        return this.connected;
    }

    getPlayerID = () => {
        var id = "";
        if(this.keycloak?.tokenParsed?.['user_id']){
            id = this.keycloak.tokenParsed['user_id'];
        } else if(this.isGuest()){
            id = localStorage.getItem('guestID');
        } else if(this.playerId != undefined){
            id = this.playerId;
        }
        return id;
    }

    getDeviceID = () => {
        return this.deviceId;
    }

    getNickname = () => {
        if (localStorage.getItem("RunningInDebug")) {
            return localStorage.getItem("NicknameOverride");
        } else if (this.isGuest()) {
            return undefined;
        } else{
            return undefined;

            // Disabled for now
            // Remove possibilty of nickname being player id by mistake
            // const id = this.keycloak?.tokenParsed?.['user_id'];
            // const nickname = this.keycloak?.tokenParsed?.['idp_providers']?.['streamsix'];
            // return id != nickname ? nickname : undefined;
        }
    }

    getTeamName = () => {
        if(localStorage.getItem("RunningInDebug")) {
            return localStorage.getItem("TeamNameOverride");
        }
        return undefined;
    }

    getFirstName = () => {
        if(localStorage.getItem("RunningInDebug")) {
            return localStorage.getItem("FirstNameOverride");
        }
        return undefined;
    }

    getLastName = () => {
        if(localStorage.getItem("RunningInDebug")) {
            return localStorage.getItem("LastNameOverride");
        }
        return undefined;
    }

    getPlayerInfo = () =>{
        return {
            "id": this.playerId,
            "deviceID": this.deviceId,
            "nickname": this.getNickname(),
        };
    }
}