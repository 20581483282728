import React, { Fragment, useEffect, useRef, useState } from 'react';
import { BusClientOperation } from '../rmb/rmb-lib';
import { Unity, useUnityContext } from 'react-unity-webgl';
import { isAuthenticated, getUserFullName } from '../auth/keycloak';
import { rmbStatus, clearStorageButSaveKeys } from '../util/util';
import NoSleep from 'nosleep.js';
import { ModalInputWrapper, openInputField, closeInputField } from '../modal/ModalInput';
import { ModalSurveyWrapper, openSurveyModal, closeSurveyModal } from '../modal/ModalSurvey';
import { VERSION } from '../util/VersionNumber';
import ExitIcon from '../assets/icons/CloseIcon.png';

const GamePage = ({ keycloak, rmbClient }) => {
    let wakeLock = null;
    const initializedRef = useRef(false);
    const unityContainerRef = useRef(null);
    const unityCanvasRef = useRef(null);
    const activeInputConfig = useRef(null);
    const activeInputRef = useRef(null);
    const activeInputIndexRef = useRef(0);
    const [refresh, setRefresh] = useState(false);
    const prevOrientation = useRef(window.innerWidth > window.innerHeight ? "Landscape" : "Portrait");

    const forceUpdate = () => setRefresh((prev) => !prev);

    const {
        unityProvider,
        loadingProgression,
        isLoaded,
        sendMessage,
        addEventListener,
        removeEventListener,
        requestFullscreen,
    } = useUnityContext({
        loaderUrl: 'unity_build/jeopardy-bar-league.loader.js',
        dataUrl: 'unity_build/jeopardy-bar-league.data.unityweb',
        frameworkUrl: 'unity_build/jeopardy-bar-league.framework.js.unityweb',
        codeUrl: 'unity_build/jeopardy-bar-league.wasm.unityweb',
        streamingAssetsUrl: 'StreamingAssets',
        print: function (message) {
            console.log(message); // Suppress alert and log messages instead
        },
        printErr: function (error) {
            console.error(error); // Log errors to the console instead of triggering an alert
        },
    });

    const onUnityLogOut = (json) => {
        console.log(`[onUnityLogOut] got logout request with the json: ${json}`);
        if (isAuthenticated(keycloak)) {
            keycloak.logout({ redirectUri: window.location.origin });
            clearStorageButSaveKeys();
        } else if (localStorage.getItem('isGuest') === 'true') {
            clearStorageButSaveKeys();
            console.log(`[onUnityLogOut] Deleted all guest data, reloading the website`);
            window.location.reload();
        } else {
            console.log(`[onUnityLogOut] failed to log out as user wasnt signed in`);
        }
    };

    const onSessionClosed = (url) => {
        if (isLoaded === true) {
            console.log(`[onSessionClosed] recieved connection closed`);
            sendMessage('UnityToWeb', 'RMBSessionClosed', url);
        } else {
            console.error(
                'This game session has ended. You may leave this game from the menu whenever ready.',
            );
        }
    };

    const onUnityReceivedMsg = (json) => {
        console.log('[GamePage] Received data:', json);
        if (isLoaded === true) {
            sendMessage('UnityToWeb', 'ProcessNetworkMsg', json); // Pass to Unity
        } else {
            console.error(`Unity is not yet loaded[${isLoaded}], ignoring msg: ${json}`);
            // Hitting this shouldnt be possible as unity must load before attaching
        }
    };

    const onUnitySendMsg = (json) => {
        console.log(`[handleUnitySendMsg] got the json: ${json}`);
        const data = JSON.parse(json);
        rmbClient.sendData(data);

        //Enable no sleep
        if (data[3100] == undefined) {
            var noSleep = new NoSleep();
            try {
                noSleep.enable();
            } catch (error) {
                console.error('Failed to enable NoSleep:', error);
            }
            console.log('[GamePage] Enabled do not sleep');
        }
    };

    const onUnitySetOpconStatus = (json) => {
        var value = JSON.parse(json);
        console.log(`[UnityOnToggleOpcon] unity requested to toggle opcon with value: ${value}`);
        rmbClient.setOpconStatus(value);
    };

    const onRMBConnectionChanged = (connectionStatus) => {
        console.log('[UnityGamePage.onRMBConnectionChanged] RMB Status Changed:', connectionStatus);
        if (isLoaded === true) {
            sendMessage('UnityToWeb', 'NetworkStatusChanged', connectionStatus);
            if (rmbClient?.roomName) {
                sendMessage('UnityToWeb', 'SetSessionID', JSON.stringify(rmbClient.roomName));
            }
        } else {
            console.error(
                `Unity is not yet loaded[${isLoaded}], failed to pass RMB Status Changed: ${connectionStatus}`,
            );
        }
    };

    const onConnected = (connectionId) => {
        console.log(`[UnityGamePage.onConnected] Connected with connection ID: ${connectionId}`);
        onRMBConnectionChanged(rmbStatus.Connected);
    };

    const onError = (url) => {
        console.log(`[UnityGamePage.onError] rmb error: ${url}`);
        onRMBConnectionChanged(rmbStatus.Disconnected);
    };

    const connectToSession = async (joinCode, nickname) => {
        try {
            onRMBConnectionChanged(rmbStatus.Connecting);
            await rmbClient.connect(joinCode, nickname);
        } catch (error) {
            console.error(`got the error: ${error}`);
            rmbClient.busClient.updateFailedAttempts('[rmb-client] Failed to post bus');
            onRMBConnectionChanged(rmbStatus.Disconnected);
        }
    };

    const onUnityLeaveGame = (json) => {
        // json should be in for the format of = { "code": "theJoinCode", "nickname": "thePlayersName"}
        console.log(`[onUnityLeaveGame] unity request to leave the game with data: ${json}`);
        rmbClient.flushCachedData();

        // Remove query parameters from the URL
        const baseUrl = window.location.origin + window.location.pathname;
        console.log('baseUrl:', baseUrl);
        window.history.replaceState({}, document.title, baseUrl);

        // Reload the page
        window.location.reload();
    };

    const onUnityCopyToClipboard = (text) => {
        console.log(
            `[UnityCopyToClipboard] unity request to CopyToClipboard with the text: ${text}`,
        );
        navigator.clipboard
            .writeText(text)
            .then(() => {
                console.log(`Copied text to clipboard: ${text}`);
                alert(`Copied text to clipboard: ${text}`);
            })
            .catch((error) => {
                console.error(`Could not copy text: ${error}`);
            });
    };

    const onUnityVibrate = (json) => {
        var value = JSON.parse(json);
        console.log(`[onUnityVibrate] unity request to vibrate with value: ${value}`);
        if (navigator.vibrate) {
            navigator.vibrate(value);
        }
    };

    const onUnityVibratePattern = (json) => {
        // json should be in for the format of = { "patternArray": int[], "length": int}
        var value = JSON.parse(json);
        var patternArray = value?.patternArray;
        var length = value?.length;
        console.log(
            `[onUnityVibrate] unity request to vibrate pattern with patternArray: ${patternArray}, with lenght: ${length}`,
        );

        if (navigator.vibrate) {
            var pattern = Array.from(
                { length: length },
                (v, i) => module.HEAP32[patternArray / 4 + i],
            );
            navigator.vibrate(pattern);
        }
    };

    const onUnityRequestPlayerInfo = (json) => {
        const token = keycloak !== undefined ? keycloak.token : 'guestToken';
        const baseUrl = window.location.origin + window.location.pathname;
        const playerInfo = {
            id: rmbClient.getPlayerID(),
            deviceID: rmbClient.getDeviceID(),
            isGuest: rmbClient.isGuest(),
            keycloack_token: token,
            autoplay: localStorage.getItem('UseAutoPlay') === 'true',
            filterID: localStorage.getItem('filterID'),
            version: VERSION,
            searchParams: localStorage.getItem('savedAppSearchParams'),
            url: baseUrl,
            env: localStorage.getItem('overrideEnv'),
            buildType: process.env.REACT_APP_BUILD_TYPE,

            // Need nickname for loadtesters
            nickname: rmbClient.getNickname(),

            // Dont preset name, let unity handle this
            // fullName: getUserFullName(keycloak),
            // teamname: rmbClient.getTeamName(),
            // firstname: rmbClient.getFirstName(),
            // lastname: rmbClient.getLastName(),
        };
        // Pass player info to Unity
        sendMessage('UnityToWeb', 'ProcessPlayerInfo', JSON.stringify(playerInfo));
    };

    const onUnitySubmitJoinCode = (json) => {
        // json should be in for the format of = { "code": "theJoinCode", "nickname": "thePlayersName"}
        const data = JSON.parse(json);
        console.log(
            `[onUnitySubmitJoinCode] unity submitted the joincode data: ${JSON.stringify(data)}`,
        );
        connectToSession(data.code, data.nickname);
    };

    const onUnityTriggerSurvey = (url) => {
        console.log(`[onUnityTriggerSurvey] unity request to show the survey monkey url: ${url}`);
        openSurveyModal(url, onUnityCloseSurvey);
    };

    const onUnityCloseSurvey = (json) => {
        console.log(`[onUnityCloseSurvey] unity request to close the survey: ${json}`);
    };

    const onUnityOpenInputField = (json) => {
        const data = JSON.parse(json);
        console.log(
            `[onUnityOpenInputField] unity requested the input field with the data: ${JSON.stringify(data)}`,
        );
        openInputField(
            data.title,
            data.description,
            data.prefix,
            data.value,
            UnityOnInputFieldComplete,
        );
    };

    const onUnityCloseInputField = (json) => {
        console.log(`[onUnityCloseInputField] unity requested to close the input field: ${json}`);
        closeInputField();
    };

    const UnityOnInputFieldComplete = (value) => {
        console.log('[UnityOnInputFieldComplete] Input value:', value);
        sendMessage('UnityToWeb', 'OnInputFieldComplete', value);
    };

    const onUnityChangeEvn = (json) => {
        const envMap = {
            1: 'stg',
            2: 'dev',
            3: 'local - prod',
            4: 'local - stg',
            5: 'local - dev',
        };
    
        const value = JSON.parse(json);
        console.log(`[onUnityChangeEvn] unity request to change env to: ${value}`);
    
        const env = envMap[value];
        if (env) {
            localStorage.setItem('overrideEnv', env);
            console.log(`[onUnityChangeEvn] change to ${env}`);
        } else {
            localStorage.removeItem('overrideEnv');
            console.log(`[onUnityChangeEvn] change to prod`);
        }
    
        onUnityLogOut('onUnityChangeEvn');
    };

    const onUnityToggleFullscreen = (json) => {
        var value = JSON.parse(json);
        console.log(
            `[onUnityToggleFullscreen] unity requested to toggle fullscreen with value: ${value}`,
        );
        //requestFullscreen(value);

        const element = document.documentElement;

        if (value) {
            // Request fullscreen
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                // Firefox
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                // Chrome, Safari and Opera
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                // IE/Edge
                element.msRequestFullscreen();
            }
        } else {
            // Exit fullscreen
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                // Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                // Chrome, Safari and Opera
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                // IE/Edge
                document.msExitFullscreen();
            }
        }
    };

    const onUnitySetLowQuality = (json) => {
        var value = JSON.parse(json);
        console.log(
            `[onUnityToggleFullscreen] unity requested to set LowQuality with value: ${value}`,
        );

        if (value === true) {
            localStorage.setItem('lowQuality', 'true');
        }
        else {
            localStorage.setItem('lowQuality', 'false');
        }

        window.location.reload();
    };

    const GetQualitySetting = () => {
        if (localStorage.getItem('lowQuality') == 'true') {
            return 1;
        } 
        return 2;
    };

    const handleModalInputActive = () => {
        console.log('handleModalInputActive');
        if (unityCanvasRef.current) {
            unityCanvasRef.current.blur();
        }
    };

    const handleModalInputClosed = () => {
        console.log('handleModalInputClosed');
        if (unityCanvasRef.current) {
            unityCanvasRef.current.focus();
        }
    };

    useEffect(() => {
        window.addEventListener('onModalInputActive', handleModalInputActive);
        window.addEventListener('onModalInputClosed', handleModalInputClosed);

        return () => {
            window.removeEventListener('onModalInputActive', handleModalInputActive);
            window.removeEventListener('onModalInputClosed', handleModalInputClosed);
        };
    }, []);

    const onUnityUpdateInputConfig = (json) => {
        console.log(`[onUnityUpdateInputConfig] got the value: ${json}`);
        const config = JSON.parse(json);
        if (config != null){
            activeInputConfig.current = {};
            console.log(`[onUnityUpdateInputConfig] config is set: ${json}`);
        } else{
            activeInputConfig.current = null;
            console.log(`[onUnityUpdateInputConfig] config is null: ${json}`);
        }
    }

    const onAdjustKeyboardHeight = (state) =>{
        if (window.visualViewport) {
            let keyboardHeight = window.innerHeight - window.visualViewport.height;
            let screenHeight = window.innerHeight;
            let percentageLost = keyboardHeight / screenHeight;

            // Send Info to Unity
            let data = {
                "keyboardHeight" : keyboardHeight,
                "screenHeight" : screenHeight,
                "percentageLost" : percentageLost,
                "orientation": prevOrientation.current,
                "state": state,
            };
            console.log("[onAdjustKeyboardHeight] sending to unity: " +  JSON.stringify(data));
            sendMessage('UnityToWeb', 'OnKeyboardHeightChanged', JSON.stringify(data));
        }
    }

    const onOrientationChange = () =>{
        const isLandscape = window.innerWidth > window.innerHeight;
        const newOrientation = isLandscape ? "Landscape" : "Portrait";
        
        if (prevOrientation.current !== newOrientation) {
            console.log(`[GamePage] Orientation changed: ${newOrientation}`);
            prevOrientation.current = newOrientation; 
            onAdjustKeyboardHeight("orientationChange");
        }
    }

    const onFocused = () =>{
        setTimeout(() => { 
            console.log("Keyboard opened, sending to unity");
            onAdjustKeyboardHeight("open"); 
        }, 400);
    }

    const onFocusLost = () =>{
        setTimeout(() => {
            console.log("Keyboard closed, ignoring sending to unity");
            onAdjustKeyboardHeight("close"); 
        }, 400);
    }

    const onUnityUpdateCursorPosition = (json) => {
        console.log(`[onUnityUpdateCursorPosition] got the value: ${json}`);
        if(activeInputRef.current){
            activeInputIndexRef.current = JSON.parse(json);
            console.log(`[onUnityUpdateCursorPosition] We have a active input field: ${activeInputRef.current.id}, setting cursorPosition to: ${activeInputIndexRef.current}`);
            activeInputRef.current.setSelectionRange(activeInputIndexRef.current, activeInputIndexRef.current); // Set the caret at the same position
        }
    }

    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (activeInputConfig.current) {
                    console.log(`activeInputConfig is set`);

                    // Handle added nodes (log when Unity adds input field)
                    mutation.addedNodes.forEach((node) => {
                        if (node.nodeType === Node.ELEMENT_NODE) {
                            const element = node;
        
                            if (element.tagName === "DIV" && element.querySelector("input[type='text']")) {
                                console.log("Unity input detected!");
                                const input = element.querySelector("input[type='text']");
                                
                                // Disable autofill at the form level
                                const form = input.closest("form");
                                if (form) {
                                    form.setAttribute("autocomplete", "off");
                                }
        
                                // Disable autofill, autocorrect, etc.
                                input.setAttribute("autocomplete", "off");
                                input.setAttribute("autocorrect", "off");
                                input.setAttribute("spellcheck", "false");
                                input.setAttribute("autocapitalize", "none");
                                input.setAttribute("id", "no-autofill-" + Math.random().toString(36).substring(2)); // Randomize input attributes to prevent autofill
                                input.setAttribute("name", "no-autofill-" + Math.random().toString(36).substring(2)); // Randomize input attributes to prevent autofill
                                input.setAttribute("type", "search"); // Set input type to 'search' to suppress autofill suggestions
        
                                // Remove appearance (hides icons like key, card, map)
                                input.style.appearance = "none";
                                input.style.webkitAppearance = "none"; // Safari
                                input.style.mozAppearance = "none"; // Firefox
    
                                input.addEventListener("input", (event) => {
                                    const cursorPosition = input.selectionStart; // Store the cursor position
                                    input.value = input.value.toUpperCase(); // Convert to uppercase
                                    input.setSelectionRange(cursorPosition, cursorPosition); // Restore cursor position
                                });
        
                                // Prevent text selection when reselecting input field
                                if (activeInputIndexRef.current && activeInputIndexRef.current <= input.value.length && activeInputIndexRef.current >= 0 ){
                                    input.setSelectionRange(activeInputIndexRef.current, activeInputIndexRef.current); 
                                }else{
                                    input.setSelectionRange(input.value.length, input.value.length); 
                                }
                                
                                // Modify wrapper styles
                                element.style.position = "absolute";
                                element.style.bottom = "120%";
                                element.style.border = "none";
                                activeInputRef.current = input;
                                
                                // Small delay to ensure rendering is updated (fix for iOS)
                                setTimeout(() => {
                                    // Modify input styles to ensure no screen shift
                                    if(activeInputRef.current){
                                        activeInputRef.current.style.position = "absolute";
                                        activeInputRef.current.style.bottom = "120%"; 
                                        activeInputRef.current.style.border = "none";
                                        forceUpdate(); 
                                    }
                                }, 100); 
                            }
                        }
                    });
    
                    // Handle removed nodes (log when Unity removes input field)
                    mutation.removedNodes.forEach((node) => {
                        if (node.nodeType === Node.ELEMENT_NODE && activeInputConfig.current) {
                            const input = node.querySelector?.("input[type='text']");
                            if (input) {
                                console.log("Unity input field removed!");
                                activeInputRef.current = null;
                                activeInputIndexRef.current = 0;
                            }
                        }
                    });
                } else {
                    console.log(`activeInputConfig is null, ignoring input field override`);
                }
            });
        });
    
        observer.observe(document.body, { childList: true, subtree: true });
        return () => {
            activeInputRef.current = null;
            activeInputIndexRef.current = 0;
            observer.disconnect();
            console.log("Mutation observer disconnected");
        }
    }, []);

    const requestWakeLock = async () => {
        try {
            if (navigator && navigator.wakeLock && document.visibilityState === 'visible') {
                wakeLock = await navigator.wakeLock.request('screen');
                console.log('[requestWakeLock] Wake Lock activated');
            } else {
                console.log('[requestWakeLock] Wake Lock not supported or page is not visible');
            }
        } catch (error) {
            console.log('[requestWakeLock] Failed to activate Wake Lock:', error);
        }
    };

    const releaseWakeLock = async () => {
        try {
            if (wakeLock !== null) {
                await wakeLock.release();
                wakeLock = null;
                console.log('Wake Lock released');
            }
        } catch (error) {
            console.log('[releaseWakeLock] Failed to release Wake Lock:', error);
        }
    };

    useEffect(() => {
        requestWakeLock();

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                requestWakeLock();
            } else {
                releaseWakeLock();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            releaseWakeLock();
        };
    }, []);

    useEffect(() => {
        console.log(`[GamePage] isLoaded has changed: ${isLoaded}`);
        if (isLoaded) {
            console.log('[GamePage] Unity has completed loading');
            if (!initializedRef.current) {
                // Ensure that initialization occurs only once
                console.log('[GamePage.UnityAttachment] Attempting to attach rmbclient to unity');
                console.log(`[GamePage.UnityAttachment] lowQuality Value: ${localStorage.getItem('lowQuality')}`);

                // Setup Rmb Client
                console.log(`[GamePage.UnityAttachment] Using up rmbClient with the config: ${JSON.stringify(rmbClient)}`);
                rmbClient.attachListener(BusClientOperation.Error, onError);
                rmbClient.attachListener(BusClientOperation.Connected, onConnected);
                rmbClient.attachListener(BusClientOperation.Data, onUnityReceivedMsg);
                rmbClient.attachListener(BusClientOperation.Closed, onSessionClosed);

                // Attach Unity to Javscript Event Listeners
                addEventListener('UnityLogOut', onUnityLogOut);
                addEventListener('UnitySendNetworkMsg', onUnitySendMsg);
                addEventListener('UnitySubmitJoinCode', onUnitySubmitJoinCode);
                addEventListener('UnityRequestPlayerInfo', onUnityRequestPlayerInfo);
                addEventListener('UnityLeaveGame', onUnityLeaveGame);
                addEventListener('UnityCopyToClipboard', onUnityCopyToClipboard);
                addEventListener('UnityVibrate', onUnityVibrate);
                addEventListener('UnityVibratePattern', onUnityVibratePattern);
                addEventListener('UnityOpenInputField', onUnityOpenInputField);
                addEventListener('UnityCloseInputField', onUnityCloseInputField);
                addEventListener('UnityToggleFullscreen', onUnityToggleFullscreen);
                addEventListener('UnitySetLowQuality', onUnitySetLowQuality);
                addEventListener('UnitySetOpconStatus', onUnitySetOpconStatus);
                addEventListener('UnityTriggerSurvey', onUnityTriggerSurvey);
                addEventListener('UnityChangeEvn', onUnityChangeEvn);
                addEventListener('UnityUpdateCursorPosition', onUnityUpdateCursorPosition);
                addEventListener('UpdateInputConfig', onUnityUpdateInputConfig);
                
                // subscribe to general events
                document.addEventListener("focusin", onFocused);
                document.addEventListener("focusout", onFocusLost);
                
                //window.addEventListener('resize', onOrientationChange);
                window.addEventListener('orientationchange', onOrientationChange);
                if (window.screen.orientation) {
                    window.screen.orientation.addEventListener('change', onOrientationChange); // If supported, listen for screen orientation change
                }

                console.log('[GamePage.UnityAttachment] Finished attaching rmbclient to unity');

                // Finished setup
                initializedRef.current = true;
                console.log('[GamePage.UnityAttachment] completed unity setup');

                // Pass playerInfo
                onUnityRequestPlayerInfo('');
            }
        }

        return () => {
            console.log(`[GamePage.UnityAttachment] exiting with ${initializedRef.current}`);
            if (initializedRef.current) {
                // Put all clean up under logic here (if needed)
                removeEventListener('UnityLogOut', onUnityLogOut);
                removeEventListener('UnitySendNetworkMsg', onUnitySendMsg);
                removeEventListener('UnitySubmitJoinCode', onUnitySubmitJoinCode);
                removeEventListener('UnityRequestPlayerInfo', onUnityRequestPlayerInfo);
                removeEventListener('UnityLeaveGame', onUnityLeaveGame);
                removeEventListener('UnityCopyToClipboard', onUnityCopyToClipboard);
                removeEventListener('UnityVibrate', onUnityVibrate);
                removeEventListener('UnityVibratePattern', onUnityVibratePattern);
                removeEventListener('UnityOpenInputField', onUnityOpenInputField);
                removeEventListener('UnityCloseInputField', onUnityCloseInputField);
                removeEventListener('UnityToggleFullscreen', onUnityToggleFullscreen);
                removeEventListener('UnitySetOpconStatus', onUnitySetOpconStatus);
                removeEventListener('UnityTriggerSurvey', onUnityTriggerSurvey);
                removeEventListener('UnityChangeEvn', onUnityChangeEvn);
                removeEventListener('UnityUpdateCursorPosition', onUnityUpdateCursorPosition);
                removeEventListener('UpdateInputConfig', onUnityUpdateInputConfig);

                console.log('[GamePage.UnityAttachment] Cleared all attachments from rmbclient to unity');
                initializedRef.current = false;

                // Don't think this is needed but keeping a ref just incase
                rmbClient.pageClosed();
                console.log('[GamePage.UnityAttachment] rmbClient triggered pageClosed');
            }
        };
    }, [isLoaded]);

    return (
        <Fragment>
            {!isLoaded && (
                <div>
                    <div className="exit-icon-container" onClick={() => onUnityLogOut()}>
                        <img src={ExitIcon} alt="Exit" />
                    </div>
                    <div className="floating-element">
                        <div className="loading-progress">
                            <p>{Math.round(loadingProgression * 100)}%</p>
                        </div>
                        <p>Loading Game</p>
                    </div>
                </div>
            )}

            <div className="unity-container" ref={unityContainerRef}>
                <Unity
                    unityProvider={unityProvider}
                    className="unity-content"
                    ref={unityCanvasRef}
                    style={{ visibility: isLoaded ? 'visible' : 'hidden' }}
                    devicePixelRatio={GetQualitySetting()}
                />
                <ModalInputWrapper></ModalInputWrapper>
                <ModalSurveyWrapper></ModalSurveyWrapper>
            </div>
        </Fragment>
    );
};
export default GamePage;
