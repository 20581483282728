import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './ModalInput.css'; // Add your CSS styles here

function ModalInput({ title, description, prefix, currentInputValue, onFinished }) {
  const [inputValue, setInputValue] = useState(currentInputValue);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        onFinished(inputValue);
      } else if (event.key.length === 1) {
        // Append character for any single character key press
        setInputValue((prevValue) => prevValue + event.key);
      } else if (event.key === 'Backspace') {
        // Handle backspace key
        setInputValue((prevValue) => prevValue.slice(0, -1));
      }
    };

    const inputElement = inputRef.current;
    inputElement.focus();
    inputElement.addEventListener('keydown', handleKeyDown);

    // Notify Unity to disable input
    const disableUnityInput = new Event('onModalInputActive');
    window.dispatchEvent(disableUnityInput);

    return () => {
      inputElement.removeEventListener('keydown', handleKeyDown);

      // Notify Unity to enable input
      const enableUnityInput = new Event('onModalInputClosed');
      window.dispatchEvent(enableUnityInput);
    };
  }, [inputValue, onFinished]);

  const handleInputBlur = () => {
    onFinished(inputValue);
  };

  return ReactDOM.createPortal(
    <div className="modal">
      <div className="modal-content">
        {/* <h1>{title}</h1>
        <p>{description}</p>
        <p>{prefix}</p> */}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleInputBlur}
        />
      </div>
    </div>,
    document.body
  );
}

let openModalCallback = null;
let closeModalCallback = null;

export function openInputField(title, description, prefix, currentInputValue, onFinished) {
  if (openModalCallback) {
    openModalCallback(title, description, prefix, currentInputValue, onFinished);
  }
}

export function closeInputField() {
  if (closeModalCallback) {
    closeModalCallback();
  }
}

export function ModalInputWrapper() {
  const [modalProps, setModalProps] = useState(null);

  openModalCallback = (title, description, prefix, currentInputValue, onFinished) => {
    setModalProps({ title, description, prefix, currentInputValue, onFinished });
  };

  closeModalCallback = () => {
    setModalProps(null);
  };

  return (
    <>
      {modalProps && (
        <ModalInput
          {...modalProps}
          onFinished={(value) => {
            modalProps.onFinished(value);
            setModalProps(null);
          }}
        />
      )}
    </>
  );
}
