const getEnvValue = (envVar, defaultValue, varTag) => {
    const overrideEnv = localStorage.getItem('overrideEnv');
    localStorage.removeItem('RunLocal');
    if (overrideEnv === 'dev') {
        console.log(`got env overide for env[${overrideEnv}] with value ${devConfig[varTag]}`);
        return devConfig[varTag];
    } else if (overrideEnv === 'stg') {
        console.log(`got env overide for env[${overrideEnv}] with value ${stgConfig[varTag]}`);
        return stgConfig[varTag];
    } else if (overrideEnv === 'local - prod') {
        console.log(`got env overide for env[${overrideEnv}] running local in prod`);
        localStorage.setItem('RunLocal', 'true');
    } else if (overrideEnv === 'local - stg') {
        localStorage.setItem('RunLocal', 'true');
        console.log(`got env overide for env[${overrideEnv}] with value ${stgConfig[varTag]}`);
        return stgConfig[varTag];
    } else if (overrideEnv === 'local - dev') {
        localStorage.setItem('RunLocal', 'true');
        console.log(`got env overide for env[${overrideEnv}] with value ${devConfig[varTag]}`);
        return devConfig[varTag];
    }   
    
    // Commenting this out for now, if we want to use env file we uncomment this
    // if (process.env[envVar]) {
    //     return process.env[envVar];
    // }

    return defaultValue;
};

const devConfig = {
    keycloakAuthURL: 'https://stg-auth.streamsix.com/auth',
    keycloakRealm: 'Users',
    keycloakClientId: 'tp-jbl',
    keycloakGuestId: 'tp-guest',
    keycloakGuestSecret: '877db893-7cd4-49ae-a1a7-da71b58838fc',
    lobbyServiceURL: 'https://sagames-dev-api.streamsix.com/lobby-service',
    leaderboardServiceURL: 'https://sagames-dev-api.streamsix.com/leaderboards',
    inventoryServiceURL: 'https://sagames-dev-api.streamsix.com/inventory-service',
    socialServiceURL: 'https://sagames-dev-api.streamsix.com/social',
    entityManagerURL: 'https://sagames-dev-api.streamsix.com/entity-manager',
    analyticsServiceURL: 'https://sagames-dev-api.streamsix.com/analytics',
    gameLicense: '56d11073-cbd1-4d8f-b402-ef970b360c65',
    websocketProxy: 'wss://stg-proxy.playjeopardylive.com/ws',
};

const stgConfig = {
    keycloakAuthURL: 'https://stg-auth.streamsix.com/auth',
    keycloakRealm: 'Users',
    keycloakClientId: 'tp-jbl',
    keycloakGuestId: 'tp-guest',
    keycloakGuestSecret: '877db893-7cd4-49ae-a1a7-da71b58838fc',
    lobbyServiceURL: 'https://sagames-stg-api.streamsix.com/lobby-service',
    leaderboardServiceURL: 'https://sagames-stg-api.streamsix.com/leaderboards',
    inventoryServiceURL: 'https://sagames-stg-api.streamsix.com/inventory-service',
    socialServiceURL: 'https://sagames-stg-api.streamsix.com/social',
    entityManagerURL: 'https://sagames-stg-api.streamsix.com/entity-manager',
    analyticsServiceURL: 'https://sagames-stg-api.streamsix.com/analytics',
    gameLicense: '6a795c3a-955f-4f71-85da-454b7f902bd8',
    websocketProxy: 'wss://stg-proxy.playjeopardylive.com/ws',
};

const config = {
    buildType: process.env.REACT_APP_BUILD_TYPE,
    keycloakAuthURL: getEnvValue('REACT_APP_KEYCLOAK_AUTH_URL', 'https://auth.streamsix.com/auth', 'keycloakAuthURL'),
    keycloakRealm: getEnvValue('REACT_APP_REALM', 'Users', 'keycloakRealm'),
    keycloakClientId: getEnvValue('REACT_APP_CLIENT_ID', 'tp-jbl', 'keycloakClientId'),
    keycloakGuestId: getEnvValue('REACT_APP_GUEST_ID', 'tp-guest', 'keycloakGuestId'),
    keycloakGuestSecret: getEnvValue('REACT_APP_KEYCLOAK_GUEST_SECRET', 'a6dec4da-4ced-4d60-8ef6-56632427775e', 'keycloakGuestSecret'),
    lobbyServiceURL: getEnvValue('REACT_APP_LOBBY_SERVICE_URL', 'https://sagames-api.streamsix.com/lobby-service', 'lobbyServiceURL'),
    leaderboardServiceURL: getEnvValue('REACT_APP_LEADERBOARD_SERVICE_URL', 'https://sagames-api.streamsix.com/leaderboards', 'leaderboardServiceURL'),
    inventoryServiceURL: getEnvValue('REACT_APP_INVENTORY_SERVICE_URL', 'https://sagames-api.streamsix.com/inventory-service', 'inventoryServiceURL'),
    socialServiceURL: getEnvValue('REACT_APP_SOCIAL_SERVICE_URL', 'https://sagames-api.streamsix.com/social', 'socialServiceURL'),
    entityManagerURL: getEnvValue('REACT_APP_ENTITY_MANAGER_URL', 'https://sagames-api.streamsix.com/entity-manager', 'entityManagerURL'),
    analyticsServiceURL: getEnvValue('REACT_APP_ANALYTICS_SERVICE_URL', 'https://sagames-api.streamsix.com/analytics', 'analyticsServiceURL'),
    gameLicense: getEnvValue('REACT_APP_LICENSE', '3ab9a496-66cb-4826-b1bf-62e8734e2e40', 'gameLicense'),
    websocketProxy: getEnvValue('REACT_APP_WS_PROXY', 'wss://proxy.playjeopardylive.com/ws', 'websocketProxy'),
};
export default config;