import { ReactKeycloakProvider } from '@react-keycloak/web';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { isAuthenticated, keycloak } from './auth/keycloak';
import './index.css';
import reportWebVitals from './testing/reportWebVitals';
import * as serviceWorkerRegistration from './testing/serviceWorkerRegistration';
import NoSleep from 'nosleep.js';
import { VERSION } from './util/VersionNumber';
import { saveSearchParams } from './util/util';
import { checkForceNewGuest } from './util/guestDataHandler';

// Room Message Bus / Streamsix SDK
import { RMBClient } from './rmb/rmb-client';
import { GameLicenses, rmbStatus } from './util/util';

console.log(`[main] started jeopardy-web-app: ${VERSION}, mode: ${process.env.REACT_APP_BUILD_TYPE}`);
saveSearchParams();
checkForceNewGuest();

// Overriding alert as console log
window.alert = function (message) { console.error('Alert disabled:', message);  };

const getLocale = () => {
    // get browser language without the region code
    const language = navigator.language.split(/[-_]/)[0];
    console.log(`Using langauge key: ${language}`);

    switch (language) {
        // Use this format to add a new language:
        case 'en':
            return require('./translations/en.json');
        default:
            return require('./translations/en.json');
    }
};
// const messages = getLocale();

const eventLogger = (event, error) => {
    console.log('onKeycloakEvent', event, error);
};

const tokenLogger = (tokens) => {
    console.log('onKeycloakTokens', tokens);
};

const rmbClient = new RMBClient(keycloak, GameLicenses);
const onKeycloakEvent = (event, error) => {
    eventLogger(event, error);
    if (event === 'onAuthSuccess' && isAuthenticated(keycloak)) {
        console.log(`[onKeycloakEvent] User signed in:  ${keycloak}`);
        const playerId = keycloak.tokenParsed['user_id'];
        const nickname = keycloak.tokenParsed['idp_providers']['streamsix'];
        console.log(
            `[onKeycloakEvent] got the data: nickname[${nickname}] with playerId[${playerId}]`,
        );

        rmbClient.refreshKeycloakData(keycloak);
        console.log(`[onKeycloakEvent] Updated rmb client: ${JSON.stringify(rmbClient)} `);
    }
};

var noSleep = new NoSleep();
document.addEventListener(
    'click',
    function enableNoSleep() {
        document.removeEventListener('click', enableNoSleep, false);
        try {
            noSleep.enable();
        } catch (error) {
            console.error('Failed to enable NoSleep:', error);
        }
        console.log('[OnClick] Enabled no sleep on website');
    },
    false,
);

// Function to hide the address bar
function hideAddressBar() {
    if (window.innerHeight > window.innerWidth) {
        // Portrait mode
        window.scrollTo(0, 1);
    } else {
        // Landscape mode
        window.scrollTo(1, 0);
    }
    console.log('hideAddressBar');
}

// Hide address bar when the page is loaded
window.addEventListener('load', function () {
    setTimeout(hideAddressBar, 0);
});

// Hide address bar on orientation change
window.addEventListener('orientationchange', hideAddressBar);
hideAddressBar();

// Catch unhandled errors globally and log them
window.onerror = function (message, source, lineno, colno, error) {
    console.error('Global Error Caught:', message, error);
    return true; // Suppresses the default browser error handling (alert)
};

// Catch unhandled promise rejections globally
window.onunhandledrejection = function (event) {
    console.error('Unhandled Rejection Caught:', event.reason);
    event.preventDefault(); // Suppresses the React error pop-up
};

const root = createRoot(document.getElementById('root'));
root.render(
    <ReactKeycloakProvider
        authClient={keycloak}
        onEvent={onKeycloakEvent}
        onTokens={tokenLogger}
        initOptions={{ checkLoginIframe: false }}
    >
        <React.StrictMode>
            <div className="App">
                <div className="unity-container">
                    <App rmbClient={rmbClient} />
                </div>
            </div>
        </React.StrictMode>
    </ReactKeycloakProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
